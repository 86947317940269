import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PowerIcon from "@material-ui/icons/Power";

// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketFiltersModal from "../TicketFiltersModal";
import TicketsList from "../TicketsList";
import TicketsWhatsappList from "../TicketsWhatsappList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
// import TicketsQueueSelect from "../TicketsQueueSelect";
// import TicketsTagSelect from "../TicketsTagSelect";
// import TicketsSemRespostaSelect from "../TicketsSemRespostaSelect";
// import TicketsWhatsappSelect from "../TicketsWhatsappSelect";
// import { Button } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: theme.palette.background.default,
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column", // Exibe em coluna por padrão
    gap: 8, // Espaço entre os elementos
    "@media (min-width: 600px)": { // Exibe em linha a partir de uma tela média
      flexDirection: "row",
      alignItems: "center",
    },
  },

  ticketOptionsButton: {
    width: "100%", // O Paper ocupa toda a largura da tela
    maxWidth: 400, // Limite de largura (opcional)
    padding: 8, // Espaçamento interno para melhor aparência
    marginLeft: 0, // Alinha à esquerda
    display: "flex", // Exibe os botões em linha
    gap: 10, // Espaçamento uniforme entre os botões
  },

  serachInputWrapper: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },

  botaoVoltarCRM: {
    position: "fixed",
    width: "50px",
    height: "50px",
    bottom: "50px",
    left: "40px",
    backgroundColor: "#4CAF50",
    color: "#FFF",
    borderRadius: "50px",
    textAlign: "center",
    fontSize: "20px",
    boxShadow: "1px 1px 2px #888",
    zIndex: 1000,
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [ticketFiltersModalOpen, setTicketFiltersModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const userWhatsappIds = user.whatsapps.map((w) => w.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTagId, setSelectedTagId] = useState("");
  const [selectedWhatsappIds, setSelectedWhatsappIds] = useState(userWhatsappIds || []);
  const [selectedTime, setSelectedTime] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [dadosFiltrados, setDadosFiltrados] = useState(false);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    //  eslint-disable-next-line, react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  // let searchTimeout;

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      const searchedTerm = e.target.value.toLowerCase();

      // clearTimeout(searchTimeout);

      if (searchedTerm === "") {
        setSearchParam(searchedTerm);
        // setTab("open");
        return;
      }

      setSearchParam(searchedTerm);

      // searchTimeout = setTimeout(() => {
      //   setSearchParam(searchedTerm);
      // }, 500);
    }
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleTicketFilters = (value) => {
    if (value.selectedQueueIds && value.selectedQueueIds.length != userQueueIds.length) {
      setSelectedQueueIds(value.selectedQueueIds || []);
      setDadosFiltrados(true);
    } else if (value.selectedTagId) {
      setSelectedTagId(value.selectedTagId);
      setDadosFiltrados(true);
    } else if (value.selectedWhatsappIds && value.selectedWhatsappIds.length != userWhatsappIds.length) {
      setSelectedWhatsappIds(value.selectedWhatsappIds || []);
      setDadosFiltrados(true);
    } else if (value.selectedTime) {
      setSelectedTime(value.selectedTime);
      setDadosFiltrados(true);
    } else if (value.dataInicio && value.dataFim) {
      setDataInicio(value.dataInicio);
      setDataFim(value.dataFim);
      setDadosFiltrados(true);
    } else {
      setDadosFiltrados(false);
    }
    setTicketFiltersModalOpen(false);
  };

  const handleLimparFilters = () => {
    setDataInicio("");
    setDataFim("");
    setSelectedQueueIds(userQueueIds || []);
    setSelectedTagId("");
    setSelectedWhatsappIds(userWhatsappIds || []);
    setSelectedTime("");
    setDadosFiltrados(false);
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        whatsApps={user?.whatsapps}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <TicketFiltersModal
        modalOpen={ticketFiltersModalOpen}
        user={user}
        onClose={(value) => handleTicketFilters(value)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"conexoes"}
            icon={<PowerIcon />}
            label={"Conexões"}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.serachInputWrapper}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onKeyDown={handleSearch}
            />
          </div>
        ) : (<></>)}
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsButton}>
        <ButtonWithSpinner
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setNewTicketModalOpen(true)}
          style={{ marginLeft: '0', width: 'fit-content' }}
        >
          Novo lead
        </ButtonWithSpinner>
        {dadosFiltrados ? (
          <ButtonWithSpinner
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => handleLimparFilters()}
            style={{ marginLeft: '10', width: 'fit-content' }}
          >
            Limpar filtros
          </ButtonWithSpinner>
        ) : (
          <ButtonWithSpinner
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setTicketFiltersModalOpen(true)}
            style={{ marginLeft: '10', width: 'fit-content' }}
          >
            Filtrar lead
          </ButtonWithSpinner>
        )}
      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        {/*  <TabPanel value="open" name="open" className={classes.ticketsWrapper}>*/}
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"></link>
        <a href={localStorage.getItem("url_retorno_crm") + "/app/crm/board/index.xhtml?funil=" + localStorage.getItem("funilId")}
          className={classes.botaoVoltarCRM} target="_self" title="Retornar ao board CRM">
          <i style={{ marginTop: "16px" }} className="fa fa-mail-reply"></i>
        </a>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                overlap="rectangular"
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            selectedWhatsappIds={selectedWhatsappIds}
            selectedTime={selectedTime}
            selectedTag={selectedTagId}
            dataInicio={dataInicio}
            dataFim={dataFim}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
         
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="conexoes" className={classes.ticketsWrapper}>
        <TicketsWhatsappList
          whatsAppsIds={selectedWhatsappIds} />
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TicketsList
          searchParam={searchParam}
          selectedQueueIds={selectedQueueIds}
          selectedWhatsappIds={selectedWhatsappIds}
          selectedTime={selectedTime}
          selectedTag={selectedTagId}
          dataInicio={dataInicio}
          dataFim={dataFim}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManager;
